import logo from './qranberri_007.png';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>

        </p>
        <a
          className="App-link"
          href="https://www.qranberri.com"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={logo} className="App-logo" alt="logo" />

        </a>
	  <p>
	  <div id="mercury"></div>
	  </p>
      </header>
	  <Footer />
    </div>
  );
}

export default App;
