import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";

const Footer = () => {
return (
	
	<h4 style={{ color: "green",
				textAlign: "center",
				marginTop: "-50px" }}>
		© 2023 Qranberri
	</h4>
	

);
};
export default Footer;
